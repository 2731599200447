import React from 'react';
import './App.css';

function App() {
    return (
        <div className='App'>
            <header className='App-header'>
                <div>
                    Your
                    <a className='maven-link' href='https://maven.apache.org' target='blank'> Maven </a>
                    and
                    <a className='gradle-link' href='https://gradle.org' target='blank'> Gradle </a>
                    artifacts repository cloud
                </div>
                <div className='main-login-suggestion'>
                    <a href='/sign-up'>Create account</a>
                    or
                    <a href='https://cloudrep.veritaris.me/repos'>discover public repositories</a>
    
                    <span className='already-registered-log-in'>
                        Already registered?
                        <a className='login-link' href='/login'> Log in</a>
                    </span>
                </div>
            </header>
        </div>
    );
}

export default App;
